import { breakpoints } from "@/theme";

export type Breakpoint = keyof typeof breakpoints;

export const getMediaQuery = (size: Breakpoint | `<${Breakpoint}`) => {
  const isDown = size.startsWith("<");
  const breakpoint = (isDown === true ? size.slice(1) : size) as Breakpoint;

  return isDown === true
    ? `(max-width: ${breakpoints[breakpoint] - 0.02}px)`
    : `(min-width: ${breakpoints[breakpoint]}px)`;
};

export const getMedia = (size: Breakpoint | `<${Breakpoint}`) => {
  return window.matchMedia(getMediaQuery(size));
};
