export const breakpoints = {
  xxs: 360,
  xs: 425,
  sm: 575,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  xxxl: 1920,
};
