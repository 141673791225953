import { Breakpoint, getMedia } from "@/utils/media";
import debounce from "lodash/debounce";

export class AppHeader {
  private scrollY = window.scrollY;
  private scrollDirection: "up" | "down" = "up";
  private breakpoint: Breakpoint = "md";
  private media: MediaQueryList;

  constructor(private header: HTMLElement) {
    this.breakpoint = this.header.getAttribute("mobile") as Breakpoint;
    this.media = getMedia(this.breakpoint);

    this.create();
  }

  private create() {
    const hamburger = this.header.querySelector(".hamburger");

    hamburger?.addEventListener("click", () => {
      this.header.classList.toggle("is-active");
      hamburger.classList.toggle("is-active");
    });

    window.addEventListener("popstate", () => {
      this.header.classList.remove("is-active");
      hamburger?.classList.remove("is-active");
    });

    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("resize", this.onResize);

    this.media.addEventListener("change", this.update);

    this.update();
  }

  private onResize = debounce(
    () => {
      this.onScroll();
    },
    300,
    {
      leading: true,
      trailing: true,
    },
  );

  private onScroll = () => {
    this.scrollDirection = this.scrollY < window.scrollY ? "down" : "up";
    this.scrollY = window.scrollY;
    this.update();
  };

  private update = () => {
    if (this.media.matches === true) {
      const isFloating = this.scrollY > 0;
      const isVisible =
        this.scrollY > this.header.offsetHeight &&
        this.scrollDirection === "up";

      this.header.classList.toggle("is-floating", isFloating);
      this.header.classList.toggle("is-visible", isVisible);
    } else {
      this.header.classList.remove("is-floating");
      this.header.classList.remove("is-visible");
    }
  };
}
