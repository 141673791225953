import { AjaxForm } from "@/components/AjaxForm";
import { AppHeader } from "@/components/AppHeader";

export const createLayout = () => {
  window.customElements.define("ajax-form", AjaxForm);

  const header = document.querySelector<HTMLElement>("header.header");

  if (header !== null) {
    new AppHeader(header);
  }
};
